var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "container flex items-center justify-center mx-auto" },
    [
      _c("div", { staticClass: "w-3/5 pt-6 bg-white" }, [
        _c("div", { staticClass: "flex w-full mb-4" }, [
          _c("p", { staticClass: "skyhub-form-label" }, [
            _vm._v("Create Version")
          ]),
          _c(
            "div",
            { staticClass: "flex justify-end w-full" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: "package.detail" },
                    active: false,
                    disabled: _vm.submitted
                  }
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mt-2 text-3xl text-gray-800",
                    attrs: { icon: ["fas", "times"] }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _c(
          "form",
          { staticClass: "w-full", attrs: { onsubmit: "return false" } },
          [
            _c("p", { staticClass: "skyhub-input-field-label" }, [
              _vm._v("VERSION (REQUIRED)")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.version.version,
                  expression: "version.version"
                }
              ],
              staticClass: "w-11/12 mb-4 skyhub-input-field",
              attrs: {
                id: "inline-version",
                type: "text",
                placeholder: "",
                "data-cy": "version-input",
                disabled: _vm.submitted
              },
              domProps: { value: _vm.version.version },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.version, "version", $event.target.value)
                }
              }
            }),
            _c("p", { staticClass: "skyhub-input-field-label" }, [
              _vm._v("PLATFORM (REQUIRED)")
            ]),
            _c(
              "div",
              { staticClass: "flex w-11/12 skyhub-input-field" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedPlatform,
                        expression: "selectedPlatform"
                      }
                    ],
                    staticClass:
                      "w-11/12 leading-tight text-black outline-none appearance-none",
                    attrs: { id: "dropdown-platform", disabled: _vm.submitted },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedPlatform = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { disabled: "", value: "0" } }, [
                      _vm._v("Select a platform")
                    ]),
                    _vm._l(_vm.platformTypes, function(type) {
                      return _c(
                        "option",
                        {
                          key: _vm.platformLabels.get(type),
                          domProps: { value: type + 1 }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.platformLabels.get(type)) + " "
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _c("font-awesome-icon", {
                  staticClass: "mr-4 text-2xl",
                  attrs: { icon: ["fa", "chevron-down"] }
                })
              ],
              1
            ),
            _c("div", { staticClass: "pt-4 md:w-1/3" }),
            _c("label", { staticClass: "block py-4 font-bold" }, [
              _c(
                "button",
                {
                  staticClass:
                    "items-center w-5 h-5 align-middle bg-white border border-black rounded",
                  attrs: { checked: "checked", disabled: _vm.submitted },
                  on: {
                    click: function($event) {
                      _vm.isLatest = !_vm.isLatest
                    }
                  }
                },
                [
                  _vm.isLatest
                    ? _c("font-awesome-icon", {
                        staticClass: "mb-2 mr-2 text-lg",
                        attrs: { icon: ["fa", "check"] }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("span", { staticClass: "text-sm" }, [
                _vm._v(" Set Package Latest ")
              ])
            ]),
            _c("div", { staticClass: "pt-2" }, [
              !_vm.showProgressBar
                ? _c("div", [
                    _c("p", { staticClass: "pb-2 text-gray-500" }, [
                      _vm._v("Package file (required)")
                    ]),
                    _vm.selectedFileName === ""
                      ? _c("div", { staticClass: "mb-6" }, [
                          _c(
                            "button",
                            {
                              staticClass: "skyhub-input-button",
                              attrs: {
                                id: "chooseFilesButton",
                                disabled: _vm.submitted
                              },
                              on: { click: _vm.chooseFiles }
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-2 text-gray-900 text-1xl",
                                attrs: { icon: ["fas", "cloud-upload-alt"] }
                              }),
                              _vm._v(" Select File ")
                            ],
                            1
                          )
                        ])
                      : _c(
                          "div",
                          { staticClass: "flex-col justify-start w-full mb-6" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "text-left skyhub-input-field",
                                attrs: {
                                  type: "text",
                                  "data-cy": "version-input"
                                },
                                on: { click: _vm.chooseFiles }
                              },
                              [_vm._v(" " + _vm._s(_vm.selectedFileName) + " ")]
                            )
                          ]
                        )
                  ])
                : _vm._e(),
              _c("input", {
                staticClass: "hidden",
                attrs: {
                  id: "fileUpload",
                  type: "file",
                  hidden: "",
                  "data-cy": "file-upload-input"
                },
                on: { change: _vm.fileHandler }
              }),
              !_vm.showProgressBar
                ? _c("div", [
                    _vm.error
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "px-4 py-3 mb-4 text-red-700 bg-red-100 border border-red-400 rounded",
                            attrs: { role: "alert" },
                            on: {
                              click: function($event) {
                                _vm.error = ""
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "block text-xs sm:inline" },
                              [_vm._v(_vm._s(_vm.error))]
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "flex justify-end w-full" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "package.detail" },
                              active: false
                            }
                          },
                          [
                            _c("p", { staticClass: "mt-2 mr-8" }, [
                              _vm._v("Cancel")
                            ])
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "skyhub-button",
                            class: { "opacity-50": _vm.hasEmptyForm() },
                            attrs: {
                              id: "inline-create-version",
                              type: "button",
                              "data-cy": "submit-version-button",
                              disabled: _vm.submitted
                            },
                            on: { click: _vm.submitVersionForm }
                          },
                          [_vm._v(" Create Version ")]
                        )
                      ],
                      1
                    )
                  ])
                : _c("div", { staticClass: "relative pt-1" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex h-2 mb-4 overflow-hidden text-xs bg-gray-400 rounded"
                      },
                      [
                        _c("div", {
                          staticClass:
                            "flex flex-col justify-center text-center text-white bg-gray-800 shadow-none whitespace-nowrap",
                          style:
                            "width:" +
                            _vm.progressPercentage +
                            "%; transition: width 1s ease 1s;"
                        })
                      ]
                    )
                  ])
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }