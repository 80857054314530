


























































































































































import { Component, Vue } from 'vue-property-decorator';
import { CreatePackageVersionDTO } from '@/models/packages/PackageVersion';
import {
  PackagePlatformType,
  PackagePlatformLabels
} from '@/models/packages/PackagePlatformType';

@Component<CreatePackagePage>({})
export default class CreatePackagePage extends Vue {
  private invalidStrings = ['\0'];
  private isLatest = true;
  private submitted = false;
  private error = '';
  private file!: File;
  private selectedPlatform = 0;
  private platformTypes = Object.values(PackagePlatformType).filter(
    (x) => typeof x === 'number'
  );
  private platformLabels = PackagePlatformLabels;
  private selectedFileName = '';
  private showProgressBar = false;
  private version: CreatePackageVersionDTO = {
    version: '',
    platform: PackagePlatformType.UNKNOWN,
    md5: '',
    fileName: '',
    packageLocation: ''
  };

  get progressPercentage() {
    return this.$store.getters['packageModule/uploadProgressPercentage'];
  }

  async submitVersionForm() {
    if (!this.validForm()) {
      return;
    }
    if (this.submitted) {
      return;
    }

    this.submitted = true;
    this.selectedFileName = '';
    this.showProgressBar = true;

    const presignedUrlResponse = await this.$store.dispatch(
      'packageModule/fetchPresignedUploadUrl'
    );

    try {
      const responseHeaders = await this.$store.dispatch(
        'packageModule/uploadObjectToFileStorage',
        { url: presignedUrlResponse.url, file: this.file }
      );
      if (!responseHeaders) {
        this.selectedFileName = this.file.name;
        this.showProgressBar = false;
        return;
      }

      const md5 = await this.parseMd5(responseHeaders['etag']);
      this.version.md5 = md5;

      // account for 1 based indexing in dropdown
      this.version.platform = this.selectedPlatform - 1;

      this.version.packageLocation = presignedUrlResponse.packageLocation;
      if (responseHeaders['location']) {
        this.version.packageLocation = responseHeaders['location'];
      }
      this.version.fileName = this.file.name;
      await this.publishVersion();
    } catch (error) {
      this.showProgressBar = false;
      this.error = error.toString();
    }
  }

  async publishVersion() {
    await this.$store.dispatch('packageModule/createPackageVersion', {
      packageId: this.$route.params.id,
      version: this.version,
      isLatest: this.isLatest
    });
    this.$router.push({
      name: 'package.detail'
    });
  }

  async parseMd5(etag: string): Promise<string> {
    return etag.replace(/"/g, '').toUpperCase();
  }

  fileHandler(e: Event) {
    const event = e.target as HTMLInputElement;
    if (event.files && event.files.length) {
      this.file = event.files[0];
      this.selectedFileName = this.file.name;
    }
  }

  private chooseFiles() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fileElement = document.getElementById('fileUpload')!;
    fileElement.click();
  }

  private hasEmptyForm(): boolean {
    return (
      this.version.version === '' ||
      this.selectedPlatform === 0 ||
      this.file == null // null or undefined
    );
  }

  private validForm() {
    if (this.hasEmptyForm()) {
      return false;
    }
    const invalidStrings = this.invalidStrings;
    function isInvalidString(value: string) {
      return invalidStrings.some((substring) => value.includes(substring));
    }
    if (isInvalidString(this.version.version)) {
      this.error = 'Version contains invalid characters';
      return false;
    }
    if (isInvalidString(this.file.name)) {
      this.error = 'Filename contains invalid characters';
      return false;
    }
    return true;
  }
}
